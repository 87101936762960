.cart-dropdown {
    position: absolute;
    right: 0;
    top: 50px;
    width: 250px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 5px;
    z-index: 1000;
  }
  
  .cart-dropdown h3 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
    color: #333;
  }
  
  .cart-items-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
    color: #333;
  }
  
  .remove-item-button {
    background: none;
    border: none;
    color: red;
    font-size: 1.2em;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
  }
  
  .remove-item-button:hover {
    color: darkred;
  }
  
  .checkout-button,
  .clear-cart-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    margin-top: 10px;
    transition: background-color 0.3s;
  }
  
  .checkout-button:hover,
  .clear-cart-button:hover {
    background-color: #218838;
  }
  