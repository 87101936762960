.recipe-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 250px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    background-color: #fff;
  }
  
  .recipe-item:hover {
    transform: scale(1.05);
  }
  
  .recipe-image {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
  }
  
  .recipe-info {
    padding: 16px;
    text-align: center;
  }
  
  .recipe-title {
    font-family: 'Bricolage Grotesque', sans-serif;
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 8px;
  }
  
  .recipe-time,
  .recipe-level {
    font-family: 'Arial', sans-serif;
    color: #666;
    font-size: 1rem;
    margin: 5px 0;
  }
  