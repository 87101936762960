.header {
    display: flex;
    justify-content: space-between; /* Utilisation de space-between pour séparer les éléments */
    align-items: center;
    background-color: #00493B;
    color: white;
    padding: 24px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    font-family: "Bricolage Grotesque", sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-right: 10px;
}

.mini-oven {
    width: 30px;
    height: 20px;
    background: #8B4513;
    border-radius: 3px;
    position: relative;
}

.mini-oven::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 10px;
    background: #555;
    border-radius: 50%;
}

.mini-oven::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 10px;
    background: #444;
    border-radius: 3px 3px 0 0;
}

.flame {
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 15px;
    background: orange;
    border-radius: 50% 50% 0 0;
    animation: flicker 0.5s infinite;
}

.flame::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 10px;
    background: red;
    border-radius: 50% 50% 0 0;
}

@keyframes flicker {
    0%, 100% {
        transform: translateX(-50%) scaleY(1);
        opacity: 1;
    }
    50% {
        transform: translateX(-50%) scaleY(1.2);
        opacity: 0.8;
    }
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

nav ul li {
    flex: 1;
    text-align: center;
    min-width: 100px;
    max-width: 200px;
}

nav ul li a {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    display: block;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

nav ul li:hover .dropdown-content {
    display: block;
    background-color: #00493B;
    border-radius: 5px;
    
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #444;
    min-width: 120px;
    z-index: 1;
    border-radius: 4px;
}

.dropdown-content li {
    border-bottom: 1px solid #555;
    display: contents;
}

.dropdown-content li a {
    padding: 8px 15px;
    font-size: 14px;
}

.cart-icon-container {
    position: relative;
    cursor: pointer;
}

.cart-icon {
    width: 30px;
    height: 30px;
}

.cart-dropdown {
    position: absolute;
    right: 0;
    top: 50px; /* Ajustez en fonction de la hauteur de votre header */
    width: 250px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 5px;
    z-index: 1000;
}

.cart-dropdown h3 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
    color: #333;
}

.cart-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.cart-dropdown li {
    display: flex;
    justify-content: space-between; /* Aligne le texte et la croix rouge */
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
    color: #333;
}

.remove-item-button {
    background: none;
    border: none;
    color: red;
    font-size: 1.2em;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.remove-item-button:hover {
    color: darkred;
}

.checkout-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.checkout-button:hover {
    background-color: #218838;
}

.clear-cart-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    margin-top: 5px;
    transition: background-color 0.3s;
}

.clear-cart-button:hover {
    background-color: #c82333;
}

.menu-toggle {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .menu-toggle {
        display: block;
    }

    nav ul {
        flex-direction: column;
        position: absolute;
        top: 70px;
        left: -100%;
        width: 100%;
        background-color: #00493B;
        transition: left 0.3s ease;
    }

    nav ul.showing {
        left: 0;
    }

    nav ul li {
        text-align: left;
        margin: 0;
    }

    nav ul li a {
        padding: 15px 20px;
        border-bottom: 1px solid #555;
    }

    .dropdown-content {
        position: static;
        border: none;
        background-color: #00493B;
    }

    .dropdown-content li a {
        padding-left: 30px;
    }

    .cart-icon-container {
        position: absolute;
        right: 65px;
        top: 25px;
    }
}

@media (max-width: 480px) {
    .logo {
        font-size: 20px;
    }

    .menu-toggle {
        font-size: 20px;
    }

    nav ul li a {
        font-size: 14px;
    }

    .cart-icon {
        width: 20px;
        height: 20px;
    }
}
