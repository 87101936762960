.marques-container {
  background-color: #FBF8F3;
  padding: 1px 0;
  text-align: center;
}

.featured-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  color: #00493B;
  margin-bottom: 20px; /* Réduction de la marge pour rapprocher les éléments */
  margin-top: 20px; /* Réduction de la marge supérieure pour positionner plus haut */
}

.marques-logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -10px; /* Réduction de la marge supérieure pour positionner les logos plus haut */
}

.marque-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 150px; /* Réduction de la largeur maximale des items */
  margin: 0 15px; /* Réduction des marges autour des items */
}

.marque-item img {
  max-width: 100%;
  height: auto;
  max-height: 180px; /* Limite la hauteur maximale des images */
  margin-bottom: 10px; /* Réduction de la marge sous les images */
}

.marque-item p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #00493B;
  text-align: center;
}
  @media (min-width: 481px) and (max-width: 1024px) {
    .featured-title {
      font-size: 22px; /* Taille de police légèrement réduite pour tablettes */
      margin-bottom: 30px;
    }
  
    .marque-item {
      max-width: 150px; /* Réduit la largeur maximale des logos sur tablette */
      margin: 0 15px;
    }
  
    .marque-item img {
      max-width: 80%; /* Réduit la taille des images sur tablette */
    }
  
    .marque-item p {
      font-size: 14px; /* Taille de police légèrement réduite */
    }
  }
  
  /* Pour les téléphones (480px et moins) */
  @media (max-width: 480px) {
    .featured-title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  
    .marques-logos {
      flex-direction: row; /* Aligne les éléments horizontalement */
      justify-content: center; /* Centre les éléments dans chaque ligne */
      flex-wrap: wrap; /* Permet le retour à la ligne */
    }
  
    .marque-item {
      flex: 0 1 calc(50% - 10px); /* Chaque élément prend 50% de la largeur, moins 10px de marge */
      margin: 5px; /* Ajoute une marge autour des éléments */
      max-width: 100px; /* Réduit la largeur maximale des éléments */
    }
  
    .marque-item img {
      max-width: 80%; /* Réduit encore la taille des images */
      margin-bottom: 8px;
    }
  
    .marque-item p {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }