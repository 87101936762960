@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

body {
  font-family: 'Sora', sans-serif; /* Applique la police Sora à tout le corps de la page */
  margin: 0;
  padding: 0;
}

.recipes-title {
  font-size: 2.5em; /* Taille du texte du titre */
  font-weight: 700; /* Poids du texte pour le titre */
  color: #00493B; /* Couleur du texte */
  margin: 40px 0 20px; /* Espace sous le titre */
  text-align: center; /* Centre le texte horizontalement */
}

.recipe-list {
  position: relative;
  display: flex;
  justify-content: center; /* Centre les éléments horizontalement */
  gap: 20px; /* Espace entre les items */
  overflow-x: auto; /* Permet le défilement horizontal */
  scroll-snap-type: x mandatory; /* Active l'effet de snap lors du scroll horizontal */
  -webkit-overflow-scrolling: touch; /* Améliore le défilement sur les appareils iOS */
  padding: 10px; /* Espace à l'intérieur de la zone de défilement */
  scrollbar-width: none; /* Masque la barre de défilement sur Firefox */
  margin-bottom: 40px; /* Espace en bas de la liste de recettes */
  max-width: 100%; /* Utilise toute la largeur disponible */
  box-sizing: border-box; /* Assure que la largeur inclut padding et bordure */
}

.recipe-list::-webkit-scrollbar {
  display: none; /* Masque la barre de défilement sur Chrome, Safari et Opera */
}

.recipe-item {
  flex: 0 0 auto; /* Empêche les items de s'étirer */
  width: 200px; /* Largeur fixe pour les items */
  max-width: 250px; /* Limite la taille maximum des items */
  border: 2px solid #00FF00; /* Bordure verte fluo */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  background-color: rgba(255, 255, 255, 0.9); /* Fond blanc semi-transparent */
  scroll-snap-align: start; /* Les éléments s'alignent correctement lors du défilement */
  min-width: 180px; /* Largeur minimale pour les petits écrans */
  text-align: center; /* Centre le texte à l'intérieur des items */
  padding: 10px; /* Ajoute de l'espace à l'intérieur de chaque item */
}

.recipe-item img {
  width: 100%; /* Image prend toute la largeur de l'item */
  height: auto; /* Ajuste automatiquement la hauteur pour conserver le ratio */
  border-radius: 5px; /* Coins arrondis pour les images */
}

.recipe-item:hover {
  transform: scale(1.05);
}

/* Supprime le surlignement/soulignement des composants Link */
.recipe-list a {
  text-decoration: none; /* Supprime le soulignement des liens */
  color: inherit; /* Utilise la couleur par défaut de l'élément parent */
  display: block; /* Assure que le lien prend toute la largeur de l'élément parent */
}

.cta-container3 {
  text-align: center;
  margin: 20px auto; /* Centre horizontalement avec une marge en haut et en bas */
  padding: 20px; /* Ajoute de l'espace autour du bouton */
}

.cta-button3 {
  background-color: #00493B;
  color: white;
  padding: 15px 40px;
  border: none;
  border-radius: 50px;
  font-family: 'Sora', sans-serif;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, padding 0.3s ease, font-size 0.3s ease;
}

.cta-button3:hover {
  background-color: #00382e;
}

/* Media Queries pour rendre la mise en page responsive */

@media (max-width: 1024px) { /* Pour tablettes */
  .recipes-title {
    font-size: 2em; /* Réduit la taille du titre pour tablettes */
  }

  .recipe-list {
    justify-content: flex-start; /* Aligne les éléments au début pour le swipe */
    gap: 10px; /* Réduit l'espacement entre les items pour un meilleur affichage sur tablette */
  }

  .recipe-item {
    max-width: 200px; /* Ajuste la taille max des items pour tablettes */
    width: 180px; /* Réduit légèrement la largeur pour s'adapter aux écrans plus petits */
  }
}

@media (max-width: 768px) { /* Pour téléphones */
  .recipes-title {
    font-size: 1.5em; /* Réduit la taille du titre pour les téléphones */
  }

  .recipe-list {
    justify-content: flex-start; /* Aligne les éléments au début pour le swipe */
    gap: 10px; /* Réduit l'espacement entre les items pour un meilleur affichage sur téléphone */
  }

  .recipe-item {
    max-width: 180px; /* Ajuste la taille max des items pour téléphones */
    width: 160px; /* Réduit la largeur des items pour les petits écrans */
  }
}

@media (max-width: 480px) { /* Pour petits téléphones */
  .cta-container3 {
    margin-top: 20px; /* Ajuste la marge en haut pour les petits écrans */
  }
}
