.testimonials-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 40px 0;
    background-color: #FBF8F3; /* Couleur d'arrière-plan pour la section entière */
    gap: 20px; /* Espace entre les cartes */
    flex-wrap: wrap; /* Permet aux cartes de se replier sur les écrans plus petits */
  }
  
  .testimonial-card {
    display: flex;
    background-color: #00493B;
    border-radius: 20px;
    padding: 20px;
    width: 582px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 5px solid #00FF00; /* Bordure verte fluo */
    box-sizing: border-box;
    transition: width 0.3s ease, padding 0.3s ease; /* Transition douce pour les changements de taille */
  }
  
  .profile-image {
    width: 200px;
    height: 250px;
    object-fit: cover; /* Ajuste l'image pour qu'elle occupe tout l'espace disponible */
    border-radius: 15px;
    margin-right: 20px; /* Espace entre l'image et le contenu texte */
  }
  
  .testimonial-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #FFFFFF;
    flex: 1; /* Prend le reste de l'espace disponible */
  }
  
  .rating {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: left; /* Aligne les étoiles à gauche */
  }
  
  .testimonial-text {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .testimonial-author {
    font-weight: bold;
    font-size: 18px;
  }
  
  .verified-buyer {
    color: #00FF00; /* Vert fluo pour le texte "Verified Buyer" */
    font-size: 14px;
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
  
  /* Adaptations pour les tablettes */
  @media (max-width: 1024px) {
    .testimonial-card {
      width: 80%;
      padding: 15px;
    }
    .profile-image {
      width: 150px; /* Ajuste l'image sur les tablettes */
      height: 180px;
      margin-right: 15px;
    }
    .rating {
      font-size: 20px;
    }
    .testimonial-text {
      font-size: 14px;
      line-height: 1.4;
    }
    .testimonial-author {
      font-size: 16px;
    }
    .verified-buyer {
      font-size: 12px;
    }
  }
  
  /* Adaptations pour les téléphones */
  @media (max-width: 768px) {
    .testimonial-card {
      width: 90%;
      padding: 10px;
    }
    .profile-image {
      width: 100px; /* Image plus petite pour les téléphones */
      height: 120px;
      margin-right: 10px;
    }
    .rating {
      font-size: 18px;
    }
    .testimonial-text {
      font-size: 12px;
      line-height: 1.2;
    }
    .testimonial-author {
      font-size: 14px;
    }
    .verified-buyer {
      font-size: 10px;
    }
  }
  
  /* Adaptations pour les petits téléphones */
  @media (max-width: 480px) {
    .testimonial-card {
      width: 100%;
      padding: 8px;
    }
    .profile-image {
      width: 80px; /* Image très réduite pour petits téléphones */
      height: 100px;
      margin-right: 8px;
    }
    .rating {
      font-size: 16px;
    }
    .testimonial-text {
      font-size: 10px;
      line-height: 1.1;
    }
    .testimonial-author {
      font-size: 12px;
    }
    .verified-buyer {
      font-size: 8px;
    }
  }
  