.how-to-use-container {
  background-color: #89B53E; /* Fond vert */
  padding: 40px 20px;
  text-align: center;
  color: white; /* Couleur de texte blanc */
}

.how-to-use-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.how-to-use-items {
  display: flex;
  justify-content: center;
  gap: 40px; /* Espace entre les items */
  margin-bottom: 30px;
  flex-wrap: wrap; /* Permet aux items de se replier sur les écrans plus petits */
}

.how-to-use-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 150px; /* Limite la largeur maximale des items */
}

.icon-circle {
  background-color: white; /* Fond blanc pour les icônes */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.how-to-use-item img {
  width: 30px;
  height: auto;
}

.how-to-use-item p {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}

.how-to-use-button {
  background-color: white; /* Bouton avec fond blanc */
  color: #00493B; /* Texte de bouton vert foncé */
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.how-to-use-button:hover {
  background-color: #e1e1e1; /* Changement de couleur au survol */
}

.how-to-use-offer {
  font-size: 0.9rem;
  margin-top: 10px;
  color: white;
  display: flex;
  justify-content: center;

  gap: 5px; /* Ajoute un espace entre l'icône et le texte */
}

/* Adaptations pour les tablettes */
@media (max-width: 1024px) {
  .how-to-use-title {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .icon-circle {
    width: 70px;
    height: 70px;
  }

  .how-to-use-item p {
    font-size: 1rem;
  }

  .how-to-use-button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}

/* Adaptations pour les téléphones */
@media (max-width: 768px) {
  .how-to-use-title {
    font-size: 1.75rem;
  }

  .icon-circle {
    width: 60px;
    height: 60px;
  }

  .how-to-use-item img {
    width: 25px;
  }

  .how-to-use-item p {
    font-size: 0.875rem;
  }

  .how-to-use-button {
    padding: 10px 20px;
    font-size: 0.875rem;
  }
}

/* Adaptations pour les petits téléphones */
@media (max-width: 480px) {
  .how-to-use-title {
    font-size: 1.5rem;
  }

  .icon-circle {
    width: 50px;
    height: 50px;
  }

  .how-to-use-item img {
    width: 20px;
  }

  .how-to-use-item p {
    font-size: 0.75rem;
  }

  .how-to-use-button {
    padding: 8px 15px;
    font-size: 0.75rem;
  }
}
