@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");
.greens-promo-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Prend toute la largeur de l'écran */
  background-color: #00493b;
  color: white;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.greens-promo-wrapper h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.4;

  color: white;
}

.greens-image-wrapper,
.greens-content-wrapper {
  flex: 1;
}

.greens-image-wrapper {
  background-color: #7cb342; /* Couleur d'arrière-plan de l'image */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  max-width: 650px;
  max-height: 689px;
}

.greens-promo-image {
  width: 100%;
  height: 100%;
  max-height: 552px;
  object-fit: cover;
}

.greens-content-wrapper {
  padding: 40px;
  background-color: #00493b;
  max-width: 489px;
  margin-right: 61px;
}

.greens-content-wrapper h1 {
  font-family: "Sora", sans-serif;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.4;
}

.greens-content-wrapper h1 .greens-highlight {
  text-decoration: underline;
}

.greens-subtext {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: left; /* Assure que le texte est aligné à gauche */
  width: 100%; /* S'assure que chaque ligne de texte prend toute la largeur disponible */
  word-wrap: break-word;
}

.greens-cta-button {
  background-color: #ffffff;
  color: #43ab4bff;
  padding: 15px 25px;
  border: none;
  border-radius: 50px;
  font-family: "Sora", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  width: fit-content;
}

.greens-cta-button:hover {
  background-color: #4c9452;
}

/* Responsive Design */

@media (max-width: 768px) {
  .greens-content-wrapper h1 {
    font-family: "Sora", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.4;
  }
  .greens-promo-wrapper {
    flex-direction: flex;
  }

  .greens-image-wrapper,
  .greens-content-wrapper {
    padding: 20px;
  }

  .greens-promo-image {
    max-height: 300px;
  }

  .greens-content-wrapper {
    padding: 20px;
    text-align: center;
  }

  .greens-cta-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .greens-content-wrapper h1 {
    font-size: 24px;
  }

  .greens-cta-button {
    font-size: 18px;
  }
  .greens-promo-wrapper {
    flex-direction: column;
  }
}
