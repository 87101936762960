body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* Empêche le défilement horizontal */
    background-color: #FBF8F3;
}
.container, header, nav, .left-container, .right-container {
    max-width: 100%;
    box-sizing: border-box; /* Assurez-vous que le padding est inclus dans la largeur totale */
}

.container {
    width: 100%;
    max-width: 100%;
}

nav ul li {
    flex: 1; /* Assure que chaque élément de menu s'adapte à l'espace disponible */
    min-width: auto; /* Supprimez les largeurs minimales pour les petits écrans */
}
@media (max-width: 480px) {
    nav ul {
        padding: 0;
        margin: 0;
        width: 100%; /* Assurez-vous que le menu prend toute la largeur */
    }

    nav ul li {
        width: 100%; /* Chaque élément de menu prend toute la largeur */
        min-width: auto; /* Supprimez les largeurs minimales pour les petits écrans */
    }
}