body, html {
    margin: 0;
    padding: 0;
    height: 100%; /* Assure que le body et html prennent toute la hauteur */
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
    color: #333;
  }
  
  .unique-display-page {
    min-height: 100vh; /* Prend toute la hauteur de la vue */
    padding: 20px;
    background-color: #f8f4f0; /* Couleur de fond douce si l'image de fond ne charge pas */
    background-image: url('../assets/fondvert.svg'); /* Chemin de l'image de fond */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Rend l'image de fond fixe lors du défilement */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .unique-page-title {
    font-size: 1.8em;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .unique-products-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
  }
  
  .custom-card-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px; /* Largeur fixe pour uniformiser les cartes */
    height: 400px; /* Hauteur fixe pour uniformiser les cartes */
    overflow: hidden;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }
  
  .custom-card-container:hover {
    transform: translateY(-5px);
  }
  
  .custom-image-wrapper {
    width: 100%;
    height: 150px; /* Hauteur fixe pour les images */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom-product-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .custom-card-details {
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .custom-product-title {
    font-size: 1.2em;
    margin: 10px 0;
    color: #333;
  }
  
  .custom-product-rating {
    font-size: 0.9em;
    color: #999;
    margin-bottom: 10px;
  }
  
  .custom-rating-stars {
    color: #f39c12; /* Couleur des étoiles */
  }
  
  .custom-product-price {
    font-size: 1.1em;
    color: #e74c3c; /* Couleur du prix */
    margin-bottom: 15px;
  }
  
  .custom-add-to-cart-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%; /* Bouton largeur pleine */
    transition: background-color 0.3s;
  }
  
  .custom-add-to-cart-button:hover {
    background-color: #218838;
  }
  