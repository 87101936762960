@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

.third-part-container {
  position: relative;
  width: 100%;
  min-height: 105vh; /* Change height to min-height */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  text-align: center;
  overflow: hidden; /* Consider changing this if needed */
  padding: 0 20px;
}


.titleThird {
  color: #00493B;
  font-family: 'Sora', sans-serif;
  font-size: 4rem;
  font-weight: 700;
  z-index: 3;
  margin-bottom: 40px;
  position: absolute; /* Positionnement absolu pour centrer le titre */
  top: -4%; /* Positionne le titre légèrement en dessous du haut */
  left: 50%;
  transform: translateX(-50%);
}

.features-container {
  position: relative;
  width: 100%;
  max-width: 1200px; /* Limite la largeur maximale */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px; /* Ajoute un espace pour le titre */
}

.feature-column {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 50px; /* Espace entre les fonctionnalités */
  top: 64%;
  transform: translateY(-50%);
}

.feature-column.left {
  left: 10%;
}

.feature-column.right {
  right: 10%;
}

.feature-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
}

.feature-icon {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}

.feature-title {
  font-family: 'Sora', sans-serif;
  font-size: 1.25rem;
  color: #00493B;
  margin-bottom: 5px;
}

.feature-text {
  font-family: 'Sora', sans-serif;
  font-size: 1rem;
  color: #00493B;
  line-height: 1.4;
  max-width: 200px;
  text-align: center;
}

.four-image {
  position: relative;
  z-index: 2;
  width: 400px; /* Taille ajustée de l'image */
  height: auto;
  margin: auto;
  transition: transform 0.3s ease;
}


.cta-button {
  background-color: #00493B;
  color: white;
  padding: 15px 40px;
  border: none;
  border-radius: 50px;
  font-family: 'Sora', sans-serif;
  font-size: 1.125rem;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #00382e;
}



/* Adaptations pour les tablettes et mobiles */
@media (max-width: 1024px) {
  .feature-column {
      top: 80%;
  }
  .titleThird {
    font-size: 3rem;
    top: 3%; /* Ajuste la position pour les tablettes */
  }

  .feature-column.left {
    left: 5%;
  }

  .feature-column.right {
    right: 5%;
  }

  .four-image {
    width: 300px;
  }

  .cta-button {
    padding: 12px 30px;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .titleThird {
    font-size: 2.5rem;
  }

  .feature-wrapper {
    margin-bottom: 20px;
  }

  .feature-icon {
    width: 40px;
  }

  .four-image {
    width: 250px;
  }

  .cta-button {
    padding: 10px 25px;
    font-size: 0.875rem;
  }

  .feature-title {
    font-size: 1rem;
  }

  .feature-text {
    font-size: 0.875rem;
    max-width: 150px;
  }
}

@media (max-width: 480px) {
  .titleThird {
    font-size: 19px;
    top: -5px;
  }

  .feature-column.left {
    left: 5%;
  }

  .feature-column.right {
    right: 5%;
  }

  .feature-wrapper {
    align-items: flex-start;
  }

  .four-image {
    width: 200px;
    top: 20px;
    right: 3px;
  }

  .cta-button {
    padding: 8px 20px;
    font-size: 0.75rem;
  }

  .feature-title {
    font-size: 0.875rem;
  }

  .feature-text {
    font-size: 0.75rem;
    max-width: 120px;
  }
  .feature-text{
    display: none;
  }
  .third-part-container{
    height: 75vh;
  }
.third {
position: relative;
top: 140px;
}

  .reviews-offer{
    display: block;
  }


}
