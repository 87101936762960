@import url("https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.container {
  display: flex;
  width: 100%;
  font-family: "Poppins", sans-serif;
  color: #2d2d2d;
  margin-top: 100px;
  background-color: #f9f4ea;
}

.left-container,
.right-container {
  width: 50%;
}
.right-container {
  margin-top: -350px;
}

.left-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  object-position: 0 -197px;
  background-color: #fbf8f3;
}

.right-container {
  background-color: #fbf8f3;
  padding: 60px; /* Augmente le padding pour plus d'espace intérieur */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -200px; /* Monte le container de droite de 100px */
}

.trustpilot {
  display: flex;
  align-items: center;
  font-size: 14px;

  color: #2d2d2d;
  margin-top: -110px;
}

.excellent {
  font-weight: bold;
  margin-right: 5px;
}

.trustpilot-stars {
  width: 230px;
  margin: -30px -34px;
}

.trustpilot-logo {
  width: 90px;
}

.text-content {
  margin: -37px auto;
}

.badgeFirst {
  display: inline-block;
  background-color: #5ead61;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
  font-family: "Sora", sans-serif;
}

h1 {
  color: #00493b;
  font-size: 64px; /* Augmente la taille de la police du titre */
  margin-bottom: 30px; /* Augmente l'espace en dessous du titre */
  line-height: 1.2;
  font-family: "Spectral", serif;
  font-weight: 800;
  word-break: break-word; /* Permet le retour à la ligne automatique si le titre est trop long */
  width: 100%; /* Assure que le titre prend toute la largeur disponible */
  text-align: left; /* Aligne le texte à gauche */
}

.description {
  font-size: 28px; /* Augmente la taille de la police de la description */
  font-weight: 500;
  margin-bottom: 30px; /* Augmente l'espace en dessous de la description */
  color: #00493b;
  font-family: Poppins, sans-serif;
  line-height: 36px; /* Augmente l'interligne pour une meilleure lisibilité */
  width: 100%; /* Assure que la description prend toute la largeur disponible */
  text-align: left; /* Aligne le texte à gauche */
}
.avantages {
  list-style: none;
  padding: 0;
}

.avantages li {
  margin-bottom: 12px; /* Augmente l'espace entre les éléments de la liste */
  font-size: 18px; /* Augmente la taille de la police pour les avantages */
  width: 100%; /* Assure que les avantages prennent toute la largeur disponible */
  text-align: left; /* Aligne le texte à gauche */
}

.avantages li span {
  margin-left: 8px;
  font-size: 29px;
}
ul {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

ul li {
  margin-bottom: 15px;
  font-size: 16px;
  color: #4a4a4a;
  position: relative;
  padding-left: 30px;
}

.icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
}

.cta-button {
  background-color: #5ead61;
  color: #fff;
  padding: 20px 50px; /* Plus de padding pour un bouton plus large et plus haut */
  border: none;
  border-radius: 50px; /* Bordures arrondies pour correspondre au style ovale */
  cursor: pointer;
  font-size: 24px; /* Taille de police augmentée pour un texte plus lisible */
  font-family: "Montserrat", sans-serif;
  transition: background-color 0.3s ease;
  text-align: center;
  display: inline-block;
}

.cta-button:hover {
  background-color: #4c9452;
}

.limited-offer {
  /* Taille de police augmentée pour correspondre au style de l'image */
  color: #00493b;
  text-align: center; /* Centrage du texte */
  margin-top: 20px; /* Espace au-dessus pour séparer du bouton */
  display: flex;
  justify-content: center;
  gap: 10px; /* Espace entre l'icône et le texte */
}

.limited-offer .icon1 {
  width: 14px; /* Taille de l'icône */
  height: 14px;
  display: inline-block;
}
/* Large screens (desktops, 1024px and up) */
@media (max-width: 1024px) {
  .container {
    flex-direction: row;
    margin-top: 70px;
  }

  .right-container {
    margin-top: -150px;
    padding: 50px;
  }

  .left-container {
    margin-top: -150px;
  }

  h1 {
    font-size: 48px;
  }

  .description {
    font-size: 24px;
  }

  .cta-button {
    font-size: 20px;
    padding: 15px 40px;
  }

  .limited-offer {
    font-size: 14px;
  }
}

/* Tablets (768px and up) */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin-top: 50px;
  }

  .left-container,
  .right-container {
    width: 100%;
    margin-top: 0;
  }

  .right-container {
    padding: 40px;
  }

  h1 {
    font-size: 36px;
  }

  .description {
    font-size: 20px;
  }

  .cta-button {
    font-size: 18px;
    padding: 15px 30px;
  }

  .limited-offer {
    font-size: 14px;
  }
}

/* Mobile (480px and down) */
@media (max-width: 480px) {
  .container {
    flex-direction: column;
    margin-top: 30px;
  }

  .left-container,
  .right-container {
    width: 100%;
    margin-top: 0;
  }

  .left-container {
    margin-bottom: 20px; /* Redescend l'image sur les téléphones */
  }

  .right-container {
    padding: 30px;
  }

  h1 {
    font-size: 28px;
  }

  .description {
    font-size: 18px;
  }

  .cta-button {
    font-size: 16px;
    padding: 10px 20px;
  }

  .limited-offer {
    font-size: 12px;
  }
  .product-image {
    object-position: center; /* Remet à la position par défaut sur les petits écrans */
  }
  .product-image {
    object-position: center; /* Remet à la position par défaut sur les petits écrans */
  }
  .trustpilot {
    margin-left: -15px;
  }

  .trustpilot-stars {
    width: 170px;
    margin: -19px -36px; /* Ajuste la taille et la marge pour les petits écrans */
  }
  .limited-offer {
    font-size: 8px;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .product-image {
    object-position: 0 -50px; /* Ajuste légèrement la position pour les tablettes */
  }

  .trustpilot-stars {
    width: 200px; /* Taille ajustée pour les tablettes */
    margin: -25px -34px; /* Ajuste la marge pour les tablettes */
  }
  .limited-offer {
    font-size: 0px;
  }
}
