/* src/components/CartSidebar.css */
.cart-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 350px;
  background-color: #f8f4f0;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  transform: translateX(100%);
  height: 100%;
  overflow-y: auto;
  z-index: 1000;
}

.cart-sidebar.open {
  transform: translateX(0);
}

.cart-toggle-button {
  display: block;
  margin: 10px auto;
  background-color: #00493B;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.recommended-products-section {
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.recommendation-list {
  list-style: none; /* Enlever les points uniquement ici */
  padding: 0;
  margin: 0;
}

.recommendation-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.recommendation-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 5px;
}

.recommendation-details {
  flex: 1;
}

.add-recommendation-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.add-recommendation-button:hover {
  background-color: #218838;
}

.cart-content {
  padding: 20px;
}

.cart-items-list {
  list-style: none; /* Enlever les points uniquement ici */
  padding: 0;
  margin: 0;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
}

.cart-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.cart-item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.cart-summary {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
}

.shipping-message {
  color: #007bff;
  font-size: 0.9em;
  margin-top: 10px;
}

.total-amount {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 10px;
}

.checkout-button, .clear-cart-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  background-color: #28a745;
  color: white;
  cursor: pointer;
}

.checkout-button:hover, .clear-cart-button:hover {
  background-color: #218838;
}
