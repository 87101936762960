/* src/components/QuantitySelector.css */
.quantity-input {
    width: 50px; /* Plus petit pour une meilleure compacité */
    padding: 5px;
    font-size: 14px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    -moz-appearance: textfield; /* Pour enlever les flèches dans Firefox */
  }
  
  .quantity-input::-webkit-outer-spin-button,
  .quantity-input::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Pour enlever les flèches dans Chrome */
    margin: 0;
  }
  