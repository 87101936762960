@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Bricolage Grotesque', sans-serif;
  color: white;
  overflow-x: hidden;
}

.title-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  white-space: nowrap; /* Empêche le texte de se diviser en plusieurs lignes */
}

h1.title {
  font-family: "Satisfy", cursive;
  font-weight: 800; /* Définit le poids de la police à 800 */
  font-size: 5rem; /* Ajustez la taille selon vos besoins */
  color: rgb(255, 131, 43); /* Définit la couleur du texte en orange */
  position: absolute;
  top: 20%; /* Ajuste la position verticale au-dessus des produits */
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  white-space: nowrap; /* Empêche le texte de se diviser en plusieurs lignes */
  z-index: 2; /* Assure que le texte est au-dessus de l'image */
  text-shadow: 3px 3px 5px rgba(16, 1, 1, 0.7); /* Ajoute une ombre noire sur la droite */
}
.arrow-icon {
  position: absolute;
  width: 150px; /* Ajustez la taille de l'icône */
  height: auto;
  left: 480px;
  top: 20px;
}
.pizza-icon {
  position: absolute;
  width: 150px; /* Ajustez la taille de l'icône */
  height: auto;
  left: -690px;
  top: -53px;
}
.pizza1-icon {
  position: absolute;
  width: 100px; /* Ajustez la taille de l'icône */
  height: auto;
  left: -690px;
  top: 325px;
}
.pizza2-icon {
  position: absolute;
  width: 100px; /* Ajustez la taille de l'icône */
  height: auto;
  left: -690px;
  top: 580px;
}
.pizza3-icon {
  position: absolute;
  width: 100px;
  height: auto;
  right: -690px;
  top: 280px;
}
.pizza4-icon {
  position: absolute;
  width: 100px; /* Ajustez la taille de l'icône */
  height: auto;
  right: -685px;
  top: 550px;
}
.pizza5-icon {
  position: absolute;
  width: 85px;
  height: auto;
  right: -690px;
  top: 20px;
}
.App {
  text-align: center;
}

.image-section {
  position: relative;
  width: 100%;
}

.main-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 30px auto 0; /* Ajoute 30px en haut pour descendre l'image */
}
.garantie{
  position: absolute;
  z-index: 3;
  bottom: 340px;
  right: 100px;
  height: 140px;
}

.boite-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
}

h1, p {
  margin: 10px 0;
}

/* Ajustements pour les petits écrans */
@media (max-width: 768px) {
  .text-overlay {
    padding: 0 10px;
  }

  h1 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }
}
.product-list {
  position: absolute;
  top: 50%; /* Centrer verticalement */
  left: 50%; /* Centrer horizontalement */
  transform: translate(-50%, -50%); /* Ajuste pour être exactement au centre */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%; /* Ajuster la largeur du conteneur des produits */
  gap: 20px; /* Espacement entre les produits */
}

.product-item {
  width: 200px; /* Ajuster la taille du produit */
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Fond blanc avec transparence pour voir l'image derrière */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
}

.product-title {
  font-family: 'Bricolage Grotesque', sans-serif;
  font-size: 1.5rem;
  color: #333;
  margin-top: 10px;
}

.product-description {
  font-family: 'Arial', sans-serif;
  color: #666;
  font-size: 1rem;
  margin-top: 10px;
}

.recipe-section {
  position: relative;
  width: 100%;
  height: 100vh; /* Ajuster la hauteur selon vos besoins */

}

.recipe-title-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10%; /* Positionne le titre en haut de l'image */
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Place le titre au-dessus de l'image */
  white-space: nowrap;
}

.recipe-title {
  font-family: 'Bricolage Grotesque', sans-serif;
  font-weight: 800;
  font-size: 8rem;
  color: white; /* Couleur du titre */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Ajoute une ombre au texte pour le contraste */
}
h2.recipe-title{
  font-family: 'Bricolage Grotesque', sans-serif;
  font-weight: 800;
  font-size: 8rem;
  color: white; /* Couleur du titre */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); 
  margin: 20px;
}

.recipe-list {
  position: absolute;
  bottom: 10%; /* Positionne les recettes en bas de l'image */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
  z-index: 2; /* Assure que les recettes sont au-dessus de l'image de fond */
}

.recipe-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  background-color: rgba(255, 255, 255, 0.9); /* Fond blanc semi-transparent pour voir l'image derrière */
}

.recipe-item:hover {
  transform: scale(1.05);
}

.recipe-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.recipe-info {
  padding: 16px;
  text-align: center;
}

.recipe-title-item {
  font-family: 'Bricolage Grotesque', sans-serif;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 8px;
}

.recipe-time,
.recipe-level {
  font-family: 'Arial', sans-serif;
  color: #666;
  font-size: 1rem;
  margin: 5px 0;
}

.boite-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* Place l'image boite.png en arrière-plan */
  object-fit: cover;
}
