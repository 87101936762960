.video-container {
  position: absolute;
  bottom: 20px; /* Positionné en bas */
  left: 20px; /* Positionné à gauche */
  transform: translate(0, 0); /* Pas de décalage central */
  width: 150px; /* Taille initiale réduite */
  height: 160px; /* Taille initiale réduite */
  overflow: hidden;
  border-radius: 10px; /* Coins arrondis */
  transition: width 0.3s ease, height 0.3s ease, left 0.3s ease, bottom 0.3s ease;
  z-index: 1000; /* Assure que la vidéo est au-dessus des autres éléments */
}

.video-container.expanded {
  width: 100%; /* Taille agrandie pour occuper tout l'écran */
  height: 392px; /* Hauteur ajustée */
  bottom: 0%; /* Centré verticalement */
  left: 50%; /* Centré horizontalement */
  transform: translate(-50%, -50%); /* Centre le conteneur */
  max-width: 217px; /* Largeur typique d'un smartphone */
}
.video-container.small{
  width: 90px;
}
.video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Remplit le conteneur tout en rognant les bords si nécessaire */
  object-position: center; /* Centre la vidéo dans le conteneur */
  cursor: pointer; /* Change le curseur pour indiquer que c'est cliquable */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* fond semi-transparent */
  color: white;
  font-size: 24px;
  cursor: pointer;
  border-radius: 10px;
}

.expand-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 1001;
}
.video-container.small .expand-button {
  bottom: 3px; /* Encore plus petit pour le mode réduit */
  right: 3px;
  padding: 2px 5px; /* Encore plus réduit */
  font-size: 8px; /* Taille de texte encore plus petite */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1001;
}
.video-container.small .close-button {
  top: 2px; /* Encore plus petit pour le mode réduit */
  right: 2px;
  width: 15px; /* Taille encore plus réduite */
  height: 15px; /* Taille encore plus réduite */
  font-size: 8px; /* Taille de texte encore plus petite */
  display: flex;
  align-items: center;
  justify-content: center;
}
