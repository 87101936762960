.product-card {
  background-color: white;
  color: #00493B;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  width: 280px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: border 0.3s ease;
  border: 3px solid transparent; /* Bordure par défaut transparente */
}

.product-card.selected {
  border-color: #F9A000FF; /* Bordure verte fluo si sélectionnée */
}

.badge {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #FFB400;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
  text-transform: uppercase;
}

.product-thumbnail {
  width: 227px;
  height: 87px;
  margin-bottom: 10px; /* Espacement en dessous de la miniature */
  border-radius: 5px; /* Ajoute un arrondi aux coins de l'image */
  display: block; /* Assure que l'image se comporte comme un bloc */
  margin-left: auto; /* Centrer horizontalement */
  margin-right: auto; /* Centrer horizontalement */
  object-fit: cover; /* Assure que l'image couvre entièrement sans distorsion */
  object-position: center 40%;
}

.price-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.original-price {
  text-decoration: line-through;
  color: #FF5A5F;
}

.discounted-price {
  font-weight: bold;
  font-size: 1.2em;
}

/* Styles pour les éléments de liste */
ul {
  list-style: none; /* Supprime les puces par défaut du navigateur */
  padding: 0; /* Supprime le padding par défaut */
  margin: 0; /* Supprime la marge par défaut */
}

ul li {
  font-size: 16px; /* Taille de police spécifique */
  color: #4a4a4a; /* Couleur du texte */
  position: relative; /* Position relative pour le point personnalisé */
  padding-left: 30px; /* Espace pour le point personnalisé */
  margin-bottom: 10px; /* Espacement entre les éléments de liste */
}

/* Ajout d'un point personnalisé devant chaque élément de liste */
ul li::before {
  content: '•'; /* Contenu du point */
  position: absolute; /* Position absolue par rapport au li */
  left: 10px; /* Position du point à gauche */
  color: #00493B; /* Couleur du point */
  font-size: 18px; /* Taille du point */
  line-height: 1; /* Assure un alignement vertical correct */
}
