body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f7f7f7;
    color: #333;
  }
  
  .elegant-contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    min-height: 100vh;
    background-color: #f7f7f7;
    position: relative;
    top: 86px;
  }
  
  .contact-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .contact-title {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
  }
  
  .contact-subtitle {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 40px;
  }
  
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .contact-form, .contact-info {
    width: 100%;
    max-width: 50%;
    padding: 40px;
    box-sizing: border-box;
  }
  
  .contact-form {
    border-right: 1px solid #e0e0e0;
  }
  
  .form-title, .info-title {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .elegant-input, .elegant-textarea {
    width: 100%;
    padding: 15px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 20px;
    transition: border-color 0.3s;
  }
  
  .elegant-input:focus, .elegant-textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .elegant-textarea {
    resize: none;
    height: 150px;
  }
  
  .elegant-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .elegant-button:hover {
    background-color: #0056b3;
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.2);
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .info-content p {
    margin: 15px 0;
    font-size: 1.1em;
    color: #666;
  }
  
  .footer {
    margin-top: 40px;
    text-align: center;
    color: #888;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-form, .contact-info {
      width: 90%;
      max-width: 100%;
      padding: 20px;
      border-right: none;
      border-bottom: 1px solid #e0e0e0;
    }
  
    .contact-info {
      border-bottom: none;
    }
  
    .elegant-contact-page {
      padding: 40px 15px;
    }
  
    .contact-title {
      font-size: 2em;
    }
  
    .form-title, .info-title {
      font-size: 1.5em;
    }
  }
  
  @media (max-width: 480px) {
    .contact-title {
      font-size: 1.8em;
    }
  
    .contact-subtitle {
      font-size: 1em;
    }
  
    .form-title, .info-title {
      font-size: 1.3em;
    }
  
    .elegant-input, .elegant-textarea, .elegant-button {
      font-size: 0.9em;
      padding: 12px;
    }
  
    .elegant-button {
      padding: 12px 20px;
    }
  }
  