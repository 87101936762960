.preparation-steps {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  margin-top: 157px;
}

.step-number {
  font-size: 2rem;
  font-weight: bold;
  margin-right: 10px; /* Espace entre le numéro et le texte */
  color: wheat; /* Couleur des numéros */
}

.title2 {
  font-family: 'Bricolage Grotesque', sans-serif;
  font-weight: 800;
  font-size: 2.5rem; /* Taille adaptée pour grands écrans */
  color: #333;
  margin-bottom: 30px;
}

.steps-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Espacement standard */
}

.step-item {
  display: flex;
  flex-direction: column; /* Alignement vertical par défaut */
  align-items: center;
  text-align: center;
  padding: 15px; /* Padding standard */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step-title {
  font-family: 'Bricolage Grotesque', sans-serif;
  font-size: 1.5rem; /* Taille adaptée pour les étapes */
  color: #333;
  margin-bottom: 5px;
}

.step-description {
  font-family: 'Arial', sans-serif;
  color: #666;
  font-size: 0.9rem; /* Taille standard pour les descriptions */
}

/* Media queries pour tablettes */
@media (max-width: 768px) {
  .preparation-steps {
    margin-top: 100px; /* Réduit l'espace au-dessus */
  }

  .title2 {
    font-size: 2rem; /* Taille plus petite pour tablettes */
  }

  .step-item {
    flex-direction: row; /* Alignement horizontal sur tablettes */
    text-align: left;
  }

  .step-number {
    font-size: 1.8rem; /* Taille légèrement réduite pour les numéros */
    margin-right: 15px; /* Espace ajusté entre le numéro et le texte */
    color: wheat; /* Couleur des numéros sur tablette */
  }
}

/* Media queries pour mobiles */
@media (max-width: 480px) {
  .title2 {
    font-size: 1.8rem; /* Taille plus petite pour mobiles */
  }

  .steps-container {
    gap: 15px; /* Réduit l'espacement entre les étapes */
  }

  .step-title {
    font-size: 1.2rem; /* Taille réduite pour mobiles */
  }

  .step-description {
    font-size: 0.8rem; /* Taille réduite pour mobiles */
  }

  .step-item {
    padding: 10px; /* Réduit le padding pour les mobiles */
  }

  .step-number {
    font-size: 1.5rem; /* Taille plus petite pour les numéros sur mobile */
    color: wheat; /* Couleur des numéros sur mobile */
  }
}
