.image-slider-container {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .discount-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    color: #00493B;
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .main-image {
    width: 100%;
    border-radius: 10px;
  }
  
  .thumbnail-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
  }
  
  .thumbnail {
    width: 60px;
    height: 60px;
    border-radius: 20%;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s ease;
  }
  
  .thumbnail.active {
    border-color: #00493B;
  }
  
  .navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  
  .nav-button {
    background: none; /* Suppression de l'arrière-plan */
    border: none; /* Suppression de la bordure */
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .nav-button svg {
    width: 30px; /* Ajuster la taille de l'icône SVG */
    height: 30px;
    fill: white;
    transition: fill 0.3s ease;
  }
  
  .nav-button:hover svg {
    fill: #00FF00; /* Change la couleur de la flèche au survol */
  }
  