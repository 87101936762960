.product-showcase-container {
  background-color: #00493B;
  color: white;
  padding: 40px 20px;
  text-align: center;
}
.klarna-option {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.klarna-option img {
  margin-right: 10px;
}

.header-benefits {
  display: flex;
  justify-content: space-around;
  background-color: #00382e;
  padding: 10px;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Permet de replier les éléments sur des petits écrans */
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px; /* Ajoute de l'espace entre les éléments */
}

.image-slider-container {
  flex: 1;
  max-width: 500px; /* Limite la largeur maximale */
  min-width: 300px; /* Limite la largeur minimale */
  margin-right: 30px; /* Espace entre le slider et le contenu */
}

.product-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  min-width: 300px;
}

.product-description {
  margin-bottom: 20px;
  color: #ffffff;
}

.product-rating {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.product-rating span {
  background-color: #6AC259;
  padding: 5px;
  border-radius: 5px;
  color: white;
}

.product-cards {
  display: flex;
  gap: 20px;
  justify-content: center; /* Centre les cartes de produit */
  margin-bottom: 20px;
  overflow-x: hidden; /* Empêche les débordements horizontaux */
}

.product-card {
  background-color: white;
  color: #00493B;
  padding: 30px 20px 20px; /* Ajoute plus de padding en haut pour le badge */
  border-radius: 10px;
  text-align: left;
  width: 280px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease; /* Ajoute une transition pour l'effet de survol */
}


.badge {
  position: absolute;
  top: 10px; /* Ajuste la position du badge pour le rendre pleinement visible */
  left: 10px;
  background-color: #FFB400;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  text-transform: uppercase;
  z-index: 1; /* Assure que le badge soit toujours visible par-dessus les autres éléments */
}

.price-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.original-price {
  text-decoration: line-through;
  color: #FF5A5F;
}

.discounted-price {
  font-weight: bold;
  font-size: 1.2rem;
}

.purchase-options {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap; /* Permet de replier les options d'achat sur des petits écrans */
  justify-content: center; /* Centre les options d'achat */
}

.purchase-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.buy-button {
  background-color: #FF5A5F;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buy-button:hover {
  background-color: #E04848;
}

/* Media Queries pour les tablettes et téléphones */
@media (max-width: 1024px) { /* Pour tablettes et petits ordinateurs */
  .main-content {
    flex-direction: column; /* Passe les éléments en colonne */
    align-items: center;
  }

  .image-slider-container,
  .product-info {
    max-width: 100%; /* Utilise toute la largeur disponible */
    margin-right: 0; /* Supprime l'espace entre les sections */
  }

  .product-cards {
    overflow-x: auto; /* Active le défilement horizontal */
    scroll-snap-type: x mandatory; /* Active le scroll snap */
    padding-bottom: 10px; /* Ajoute de l'espace pour le défilement */
    display: flex;
    flex-wrap: nowrap; /* Désactive le wrap pour créer un slider horizontal */
    justify-content: flex-start; /* Aligne les cartes au début */
  }

  .product-card {
    flex: 0 0 auto; /* Assure que les cartes ne se redimensionnent pas */
    scroll-snap-align: start; /* Aligne chaque carte au début du conteneur */
  }

  .purchase-options {
    gap: 10px;
    flex-direction: column; /* Passe les options en colonne */
    align-items: center;
  }
}

@media (max-width: 768px) { /* Pour téléphones et très petits écrans */
  .header-benefits {
    flex-direction: column; /* Passe les éléments en colonne */
    padding: 20px 0;
  }

  .product-cards {
    overflow-x: auto; /* Active le défilement horizontal */
    flex-wrap: nowrap; /* Désactive le wrap pour le slider horizontal */
    justify-content: flex-start; /* Aligne les cartes au début */
    gap: 20px; /* Espace entre les cartes */
  }

  .product-card {
    width: 280px; /* Maintient la largeur fixe des cartes */
    min-width: 280px; /* Définit une largeur minimale pour le slider */
    margin-right: 20px; /* Espace entre les cartes */
  }

  .buy-button {
    width: 100%; /* Le bouton prend toute la largeur sur mobile */
    padding: 12px 20px; /* Ajuste la taille du bouton sur mobile */
  }

}

@media (max-width: 480px) {
  .product-cards {
    display:contents;
    gap: 20px;
    justify-content: center; /* Centre les cartes de produit */
    margin-bottom: 20px;
    overflow-x: hidden; /* Empêche les débordements horizontaux */
  }
}
