.reviews-container {
  background-image: url('../assets/reviews.webp'); /* Assurez-vous que le chemin vers l'image est correct */
  background-size: cover;
  background-position: center;
  padding: 60px 20px;
  text-align: center;
  color: #00493B; /* Couleur de texte vert foncé */
  overflow-x: auto; /* Permet le défilement horizontal si nécessaire */
  overflow-y: hidden; /* Masque le défilement vertical */
  scrollbar-width: none; /* Masque la barre de défilement sur Firefox */
}

.reviews-container::-webkit-scrollbar {
  display: none; /* Masque la barre de défilement sur Chrome, Safari et Opera */
}

.reviews-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.reviews-grid {
  display: flex;
  flex-wrap: nowrap; /* Ne permet pas aux éléments de s'enrouler, crée un défilement horizontal */
  gap: 20px;
  margin-bottom: 30px;
  overflow-x: auto; /* Permet le défilement horizontal */
  scrollbar-width: none; /* Masque la barre de défilement sur Firefox */
  padding-bottom: 10px; /* Ajouter un peu d'espace en bas pour le défilement */
}

.reviews-grid::-webkit-scrollbar {
  display: none; /* Masque la barre de défilement sur Chrome, Safari et Opera */
}

.review-card {
  background-color: white;
  border: 2px solid #89B53E; /* Bordure verte */
  border-radius: 10px;
  padding: 20px;
  width: 280px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  flex: 0 0 auto; /* Empêche les éléments de se redimensionner */
  transition: transform 0.3s ease; /* Ajout d'une transition pour un effet de survol */
}

.review-stars {
  color: #FFD700; /* Couleur des étoiles */
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.review-text {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 20px;
}

.review-author {
  font-weight: bold;
}

.review-role {
  color: #6AC259; /* Couleur verte pour le texte 'Verified Buyer' */
}

.reviews-button {
  background-color: #00493B; /* Bouton avec fond vert foncé */
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.reviews-button:hover {
  background-color: #003026; /* Couleur du bouton au survol */
}

.reviews-offer {
  font-size: 0.9rem;
  margin-top: 10px;
  color: #00493B;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; /* Ajoute un espace entre l'icône et le texte */
}

/* Adaptations pour les tablettes */
@media (max-width: 1024px) {
  .reviews-title {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .review-card {
    width: 240px;
    padding: 15px;
  }

  .reviews-button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}

/* Adaptations pour les téléphones */
@media (max-width: 768px) {
  .reviews-title {
    font-size: 1.75rem;
  }

  .review-card {
    width: 250px;
    padding: 10px;
  }

  .review-stars {
    font-size: 1rem;
  }

  .review-text {
    font-size: 0.875rem;
    margin-bottom: 15px;
  }

  .reviews-button {
    padding: 10px 20px;
    font-size: 0.875rem;
  }
}

/* Adaptations pour les petits téléphones */
@media (max-width: 480px) {
  .reviews-title {
    font-size: 1.5rem;
  }

  .review-card {
    width: 230px;
    padding: 8px;
  }

  .review-stars {
    font-size: 0.875rem;
  }

  .review-text {
    font-size: 0.75rem;
  }

  .review-author {
    font-size: 0.875rem;
  }

  .reviews-button {
    padding: 8px 15px;
    font-size: 0.75rem;
  }
  .reviews-offer{
    align-items:baseline;
  }
}
